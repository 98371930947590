const CaraousalsImages = [
    {
        id: 1,
        image : '/images/Caraousal-01.jpg'
    },
    // {
    //     id: 2,
    //     image : '/images/Caraousal-02.jpg'
    // },
    {
        id: 3,
        image : '/images/Caraousal-03.jpg'
    },
    {
        id: 4,
        image : '/images/Caraousal-04.jpg'
    }
];

export default CaraousalsImages;