import React, { useEffect, useReducer, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import LocationModal from "../Components/LocationModal";
import NavBar from "../Components/NavBar";
import TicketBooking from "../Components/TicketBooking";
import PuffLoader from "react-spinners/PuffLoader";
import { API_URL } from "../../config";

// Initial state for the reducer
const initialState = {
    ticket: {},
    eventDetail: {},
};

// Reducer function to manage ticket and event detail state
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_RESPONSE_DATA':
            return {
                ...state,
                ticket: action.payload.ticket,
                eventDetail: action.payload.event,
            };
        default:
            return state;
    }
};

function BookingPage() {
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState); // Using useReducer for managing ticket and event detail
    const { event_id } = useParams();
    const location = useLocation();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true); // Add a loading state

    const handleLogin = (userData) => {
        // Update login status when user logs in
        setIsLoggedIn(true);
        // Optionally, save user data to local storage or API service
    };

    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };

    // Function to fetch data from API
    const getData = async (eventid) => {
        setLoading(true); // Start loading
        try {
            const ticket_id = Object.keys(location.state.bookingQuantities)[0];
            const response = await axios.get(`${API_URL}/api/invoice/${eventid}?ticket_id=${ticket_id}`);
            if (response.status === 200) { 
                const storeData = response.data;
                // Dispatch action to update ticket and event detail
                dispatch({
                    type: 'SET_RESPONSE_DATA',
                    payload: {
                        ticket: storeData.ticket,
                        event: storeData.event,
                    },
                });
            }
        } catch (error) {
            alert("Unable to get data");
        } finally {
            setLoading(false); // Stop loading
        }
    };
    
    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const event = await axios.get(`${API_URL}/api/eventdetail/${event_id}`);
                if (event.status === 200 && event.data) {
                    console.log("event.data._id :", event.data[0])
                    getData(event.data[0]._id);
                }
            } catch (error) {
                console.error("Error fetching event details:", error);
            }
        };
    
        fetchEvent();
    }, [event_id]);

    useEffect(() => {
        // Scroll to top when the component loads or data is done loading
        if (!loading) {
            window.scrollTo({
                top: 0,
                behavior: "smooth", // Adds smooth scrolling
            });
        }
    }, [loading]);

    return (
        <div>
            {showLocationModal && (
                <LocationModal
                    onClose={handleCloseLocationModal}
                    onLocationChange={handleLocationChange}
                />
            )}
            <div id="hideforSmallDevices">
                <NavBar
                    isLoggedIn={isLoggedIn}
                    userLocation={userLocation}
                    onNext={() => setShowLocationModal(true)}
                />
            </div>

            {/* Show loader while loading */}
            {loading ? (
                <div className='d-flex justify-content-center align-items-center' style={{ height: "100vh" }}>
                    <PuffLoader loading={loading} size={60} />
                </div>
            ) : (
                <TicketBooking 
                    onLogin={handleLogin} 
                    eventDetail={state.eventDetail} 
                    ticket={state.ticket} 
                    ticketids={location.state.bookingQuantities} 
                />
            )}
        </div>
    );
}

export default BookingPage;
