import { useState, useEffect, useRef } from "react";
import { TextField } from "@mui/material";
import { Button, Spinner } from "react-bootstrap"; // Import Spinner for the loader
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import auth from "../../firebaseConfig";
import apiService from "../../services/apiService";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsXCircle } from "react-icons/bs";
import "./LoginPage.css";
import SyncLoader from "react-spinners/SyncLoader";
import indian_flag from '../../Assets/flag.png'
// import { IN } from 'country-flag-icons/react/3x2'

/* global grecaptcha */

function LoginPage() {
  const [phone, setPhone] = useState("");
  const [user, setUser] = useState(null);
  const [otp, setOtp] = useState("");
  const [showOTPField, setShowOTPField] = useState(false);
  const [timer, setTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get('source');
  const recaptchaRef = useRef(null);


  useEffect(() => {
    let countdown;
    if (isResendDisabled && showOTPField) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(countdown);
            setIsResendDisabled(false);
            return 60;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [isResendDisabled, showOTPField]);

  const setupRecaptcha = () => {
    if (!recaptchaRef.current) {
      console.error("reCAPTCHA element not found.");
      return;
    }

    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha', {
        size: "invisible",
        callback: (response) => {
          console.log("Response", response);
        },
        'expired-callback': () => {
          console.log("Recaptcha expired, please try again.");
        }
      });

      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
    } else {
      if (typeof grecaptcha !== 'undefined' && window.recaptchaWidgetId) {
        grecaptcha.reset(window.recaptchaWidgetId);
      }
    }
  };

  const sendOTP = async () => {
    setLoading(true); // Set loading to true when sending OTP
    setupRecaptcha();
    const phoneNumber = "+91"+phone;
    console.log("phoneNumber", phoneNumber);
    const appVerifier = window.recaptchaVerifier;
    try {
      const confirmation = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      setTimer(60);
      setUser(confirmation);
      setShowOTPField(true);
      setIsResendDisabled(true);
    } catch (err) {
      console.error("Error during OTP send:", err.message);

      if (typeof grecaptcha !== 'undefined' && window.recaptchaWidgetId) {
        grecaptcha.reset(window.recaptchaWidgetId);
      }

      alert(`Failed to send OTP. Error: ${err.message}`);
    } finally {
      setLoading(false); // Reset loading to false after OTP is sent
    }
  };

  const verifyOtp = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true when verifying OTP
    try {
      console.log("OTP", otp);
      const data = await user.confirm(otp);
      console.log("OTP Data", data);
      if (data) {
        if(localStorage.getItem('userData')){
          localStorage.clear();
        }
        apiService.setData(data.user);
        const isUser = await apiService.get(`/user/getUserWithID/${data.user.phoneNumber}`);
        if (isUser) {
          apiService.setData(isUser);
          if (source === 'event') {
            window.location.href = "https://hfs-live-31172.web.app";
          } else if (source === 'login') {
            window.location.href = "/";
          } else if (source === 'tickets') {
            window.location.href = "/paymentgateway";
          }else if (source === 'yourtickets') {
            window.location.href = "/yourtickets";
          }  else {
            window.location.href = "/";
          }
        } else {
          window.location.href = "/signup";
        }
      }
    } catch (err) {
      console.error(err);
      alert("Invalid OTP. Please try again.");
    } finally {
      setLoading(false); // Reset loading to false after OTP is verified
    }
  };

  const onClose = () => {
    navigate('/');
  };

  return (
    <>
      <div className="login-modal">
        <div className="containerSize">
          <div className="logincontainer">
            <span onClick={onClose}>
              <BsXCircle className="closebtn bg-white rounded-pill" />
            </span>
            <h1 className="book">
              Book <span className="your-fs"> Your Front Seat</span>
            </h1>
            <p className="login">Login</p>

            {!showOTPField && (
              <>
                <form>
                  <div className="div-num">
                    <p className="enter-number">Enter your mobile number</p>
                  </div>
                  <div className="mb-3 text-start">
                    <div className="input-group mb-3">
                      <span className="input-group-text border-primary countryflag">
                        <img className="me-2 mt-1 indian_flag" src={indian_flag} alt="India" /> <span className="countryCode">+91</span>
                      </span>
                        <input
                          type="tel"
                          className="form-control mobileinput border-primary"
                          name="mobileNumber"
                          placeholder="e.g. 9226773937"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required
                        />
                    </div>

                    {/* <IN title="United States" size={10} /> */}
                    {/* <input
                      className="form-control mobileinput py-3 border-primary"
                      type="tel"
                      name="mobileNumber"
                      placeholder="+91"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    /> */}
                    {/* <PhoneInput
                      international
                      countryCallingCodeEditable={false} // Disables editing of the country calling code
                      defaultCountry="IN" // Set default country to India
                      value={phone}
                      name="mobileNumber" 
                      onChange={(e) => setPhone(e.target.value)}
                      required
                      placeholder="Enter your phone number" // Placeholder text
                    /> */}


                  </div>

                  <Button onClick={sendOTP} className="btn getotpbtn btn-primary w-50" disabled={loading}>
                    {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4}/> : "Get OTP"} {/* Loader for Get OTP */}
                  </Button>
                </form>
              </>
            )}

            {showOTPField && (
              <>
                <div className="div-num">
                  <p className="enter-number">Enter OTP</p>
                </div>
                <TextField
                  className="form-control mb-3"
                  onChange={(e) => setOtp(e.target.value)}
                  variant="outlined"
                  placeholder="xxxxxx"
                />
                <br />
                <div>
                  <Button className="btn btn-primary" onClick={verifyOtp} disabled={loading}>
                    {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4}/> : "Login"} {/* Loader for Login */}
                  </Button>
                </div>
                <div className="resend-otp">
                  <Link
                    className={`resend-text ${isResendDisabled ? "isResendDisabled" : "isResendEnabled"}`}
                    onClick={sendOTP}
                    disabled={isResendDisabled || loading}
                  >
                    Resend OTP
                  </Link>
                  {timer === 60 ? "" : <span className="countdown"> in {timer} seconds</span>}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div id="recaptcha" ref={recaptchaRef}></div>
    </>
  );
}

export default LoginPage;
