import React, { useState } from "react";
import './YourTicketPage.css'
import UserSideBar from "../Components/userSideBar";
import NavBar from "../Components/NavBar";
import LocationModal from "../Components/LocationModal";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import Reward_img from '../../Assets/reward_img.png'
import Reward_img_small from '../../Assets/reward_img_small.png'


const RewardsPage = () => {
    const [userLocation, setUserLocation] = useState('Nagpur');
    const [showLocationModal, setShowLocationModal] = useState(false);


    // Function to handle location change
    const handleLocationChange = (location) => {
        setUserLocation(location);
        setShowLocationModal(false); // Close modal after location change
    };

    // Function to handle closing the location modal
    const handleCloseLocationModal = () => {
        setShowLocationModal(false);
    };




    return (
        <>
            <div className='profilepage'>
                {/* Show LocationModal */}
                {showLocationModal && (
                    <LocationModal
                        onClose={handleCloseLocationModal}
                        onLocationChange={handleLocationChange}
                    />
                )}
                <NavBar
                    userLocation={userLocation}
                    onNext={() => setShowLocationModal(true)} />
                <div className='row' id="LargeScreenView">
                    <UserSideBar />
                    <div className='col-lg-10 col-md-10 col-sm-8 col-9'>
                        <div className='lists'>
                            <div className='d-flex justify-content-between border-bottom border-primary' style={{ marginTop: "34px" }}>
                                <span className='profileheading fw-semibold'>Your Reward Points</span>
                            </div>
                            <div className="reward_image d-flex justify-content-center">
                                <img src={Reward_img} alt="Reward Image" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-4" id="smallScreenView">
                    <div className='d-flex justify-content-center border-bottom border-primary' style={{ marginTop: "34px" }}>
                        <span className='profileheading fw-semibold'>Your Reward Points</span>
                    </div>
                    <div className="reward_image d-flex justify-content-center mb-3">
                        <img src={Reward_img_small} alt="Reward Image" />
                    </div>
                    <Link to='/userprofile' className="back-home"><IoIosArrowBack /> Back to your profile</Link>
                </div>
            </div>
        </>
    )
}

export default RewardsPage
